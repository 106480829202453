import React from "react"

import * as Icon from "react-feather"
import { graphql } from "gatsby"

import CaseDetail from "../../components/Shared/casedetails"

const seoTitle = "Regulatory Reporting As a Service (RRaaS) "
const pageTitle = "Regulatory Reporting As a Service (RRaaS)"
const subTitle = "Banking and Capital Markets"
const para = (
 ` <div>
    <h3>Business Problem </h3>
    <p>
      Trade Data, Collateral Data, Reference Data, Legal and Market Data feeds
      are collected from various golden sources.The legacy applications and
      technical debt makes capturing, transforming, aggregating and reporting
      difficult - this in turn impacts the ability to generate reports and
      report to regulators in a timely manner
    </p>
  </div>`
)
const blockquote = `"Helping banks efficiently address their global regulatory reporting requirements."`

const cardItems = [
  {
    id: 1,
    heading: `Solution`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          As regulations become more complex, buy and sellside firms are looking
          for partners who can help them address requirements across various
          jurisdictions, with the necessary workflows and data structure that
          allows firms to maximize overall accuracy and completeness in their
          regulatory processes.
        </li>
        <li>
          <Icon.Square />
          Atyeti built a regulatory reporting system incorporating a robust
          automation framework to ingest and process data received from multiple
          source systems.{" "}
        </li>
        <li>
          <Icon.Square />
          The data is converted, validated, enriched and aggregated through a
          Transformation Service and stored within a data warehouse
        </li>
        <li>
          <Icon.Square /> Dedicated services interact with the Data Sources to
          fetch data from the data sources and version them.
        </li>
        <li>
          <Icon.Square /> A reporting service is then called to send the data to
          one or more Trade Repositories (TR).
        </li>
        <li>
          <Icon.Square />
          Responses are received from the TRs and consumed through two services:
          Compliance/Archival services and Reconciliation services.
        </li>
      </>
    ),
  },
  {
    id: 2,
    heading: `Regulatory Reporting`,
    listItems: (
      <p>
        {" "}
        <p>
          <b>CAT:</b> Built a 100% automated Consolidated Audit Trail system to
          ingest, report and reconcile 30 Million messages per month with a 90%+
          ack rate
        </p>
        <p>
          <b>SFTR:</b> Built an automated reporting system that ingests data
          from 22 different Trade, Valuation, Collateral, Margin and Reference
          sources and reports out to 2 external Trade Repositories (Markit and
          DTCC)
        </p>
        <p>
          <b>MiFID II:</b> Built an automated compliance solution that
          seamlessly connects to customer trade records, enriches the data,
          allows for business logic to be applied where required, validates
          trades and reports directly to an ARM. Event Information is made
          available in real-time via an intuitive analytics dashboard and alerts
          of any noncompliance
        </p>
        <p>
          <b>QFC:</b> Built a reporting system that ingests and links together
          source data from 13 upstream transaction, reference data, valuation,
          collateral and legal systems to populate 8 separate reporting tables
          containing 128 data fields. Data is intricately linked togetherto
          provide an accurate representation of overall QFC obligations and
          retrievable within 24 hours of being requested by FDIC.
        </p>
        <p>
          <b>SEC SBSR:</b> System has been developed and put into into
          production on time.
        </p>
      </p>
    ),
  },
  {
    id: 3,
    heading: `Result`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          The Applications are 100% fully automated and support 10+ global
          jurisdictions covering 13+ source systems, 20 asset classes, 140
          different trade types and 1,000+ Reporting eligibility rule.
        </li>
        <li>
          <Icon.Square />
          The solution went live within 6 months and has reduced their annual
          spend through a managed service agreement with Atyeti.
        </li>
        <li>
          <Icon.Square />
          The RRaaS has been configured to onboard new Financial regulation in a
          seamless manner.
        </li>
      </>
    ),
  },
]

const BlogDetails = ({ data }) => (
  <CaseDetail
    seoTitle={seoTitle}
    pageTitle={pageTitle}
    cardItems={cardItems}
    subTitle={subTitle}
    para={para}
    blockquote={blockquote}
    images={data}
  />
)
export const query = graphql`
  query RegulatoryReportingQuery {
    casedetailsJson(slug: { eq: "regulatory-reporting-as-a-service" }) {
      images {
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      slug
    }
  }
`
export default BlogDetails
